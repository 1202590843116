<template>
  <div class='d-flex py-3' id='google-recaptcha'></div>
</template>

<script>
import { onBeforeMount, ref, watch } from '@vue/composition-api';
import { loadScript } from '@core/utils';

export default {
  props: {
    render: {
      type: Boolean,
      required: true,
    }
  },
  setup(props, { emit }) {
    const recaptcha = ref(null);
    const recaptchaWidgetId = ref(null);
    const captchaRendered = ref(false);

    onBeforeMount(async () => {
      loadScript('https://www.google.com/recaptcha/api.js?render=explicit', 'recaptcha-script', true, true)
        .then(() => {
          if (!window.grecaptcha) {
            console.error('Error loading reCaptcha');
            return;
          }

          recaptcha.value = window.grecaptcha;
        })
        .catch((error) => console.error('Error loading reCaptcha', error));
    });

    const renderCaptcha = () => {
      if (!recaptcha.value) {
        return emit('verification-passed');
      }

      recaptcha.value.ready(() => {
        recaptchaWidgetId.value = recaptcha.value.render('google-recaptcha', {
          'sitekey': process.env.VUE_APP_GOOGLE_RECAPTCHA_SITE_KEY,
          'callback': (token) => emit('verification-passed', token),
          'expired-callback': () => emit('verification-expired'),
          'error-callback': () => emit('verification-error'),
        });

        captchaRendered.value = true;
      });
    };

    const resetCaptcha = () => {
      if (!recaptcha.value) {
        return emit('verification-passed');
      }

      recaptcha.value.reset(recaptchaWidgetId.value);
      emit('verification-expired');
    };

    watch(() => props.render, value => {
      if (value && !captchaRendered.value) {
        renderCaptcha();
      }
    });

    return { resetCaptcha };
  }
};
</script>
