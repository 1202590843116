<template>
  <div class="auth-wrapper auth-v2">
    <div class="auth-inner">
      <router-link to="/" class="brand-logo d-flex align-center">
        <v-img :src="appLogo" alt="logo" contain class="me-3"></v-img>
      </router-link>
      <!--/ brand logo -->

      <v-row class="auth-row ma-0">
        <v-col lg="8" class="d-none d-lg-block position-relative overflow-hidden pa-0">
          <div class="auth-illustrator-wrapper">
            <!-- triangle bg -->
            <img
              height="362"
              class="auth-mask-bg"
              :src="require(`@/assets/images/misc/mask-v2-${$vuetify.theme.dark ? 'dark' : 'light'}.png`)"
            />

            <!-- tree -->
            <v-img height="226" width="300" class="auth-tree" src="@/assets/images/misc/tree-4.png"></v-img>

            <!-- 3d character -->
            <div class="d-flex align-center h-full pa-16 pe-0">
              <v-img
                contain
                max-width="100%"
                height="692"
                class="auth-3d-group"
                :src="require(`@/assets/images/3d-characters/group-${$vuetify.theme.dark ? 'dark' : 'light'}.png`)"
              ></v-img>
            </div>
          </div>
        </v-col>

        <v-col lg="4" class="d-flex align-center auth-bg pa-10 pb-0">
          <v-row>
            <v-col cols="12" sm="8" md="6" lg="12" class="mx-auto">
              <v-card flat>
                <v-card-text>
                  <p class="text-2xl font-weight-semibold text--primary mb-2">Welcome to Merchanto! 👋🏻</p>
                  <p v-if="!showCodeForm" class="mb-2">Please sign-in to your account</p>
                  <p v-else-if="twoFaType === twoFaTypeEnum.PHONE" class="mb-2">Please enter the code from SMS sent to {{ phone }}</p>
                  <p v-else-if="twoFaType === twoFaTypeEnum.AUTHENTICATOR" class="mb-2">Please enter the code from the authenticator</p>
                </v-card-text>
                <!-- login form -->
                <v-card-text>
                  <v-form v-if="!showCodeForm" ref="loginForm">
                    <v-text-field
                      v-model="email"
                      outlined
                      id="login-email"
                      label="Email"
                      placeholder="email"
                      :error-messages="errorMessages.email"
                      @change="errorMessages.email = null"
                      :rules="[validators.required, validators.emailValidator]"
                      hide-details="auto"
                      class="mb-6"
                    ></v-text-field>

                    <v-text-field
                      v-model="password"
                      outlined
                      :type="isPasswordVisible ? 'text' : 'password'"
                      id="login-password"
                      label="Password"
                      :error-messages="errorMessages.password"
                      placeholder="Password"
                      :append-icon="isPasswordVisible ? icons.mdiEyeOffOutline : icons.mdiEyeOutline"
                      :rules="[validators.required]"
                      hide-details="auto"
                      class="mb-2"
                      @click:append="isPasswordVisible = !isPasswordVisible"
                    ></v-text-field>

                    <re-captcha :render='renderCaptcha'
                      @verification-expired='captcha.expired'
                      @verification-error='captcha.error'
                      @verification-passed='captcha.passed'
                      ref='recaptchaRef'
                    />

                    <div class="d-flex align-center justify-space-between flex-wrap">
                      <v-checkbox hide-details label="Remember Me" class="mt-0"> </v-checkbox>

                      <!-- forget link -->
                      <router-link :to="{ name: 'auth-forgot-password' }" class="ms-3"> Forgot Password? </router-link>
                    </div>

                    <v-btn block color="primary" class="mt-6" @click="handleFormSubmit"
                           :disabled='renderCaptcha && !captchaVerified'> Login </v-btn>
                  </v-form>
                  <v-form class="flex" v-else ref="codeForm" @submit.prevent="submitCode">
                    <v-text-field
                      v-model="code"
                      outlined
                      id="login-code"
                      label="Code"
                      placeholder="Code"
                      :error-messages="errorMessages.code"
                      @change="errorMessages.code = null"
                      :rules="[validators.required]"
                      hide-details="auto"
                      class="mb-6"
                    ></v-text-field>
                    <div v-if="twoFaType === twoFaTypeEnum.PHONE">
                      <div class="w-full d-flex text-center justify-center mt-3" v-if="countDown">You can request a new code in {{ countDown }} seconds</div>
                      <v-col v-else cols="12" class="d-flex justify-center mt-3">
                        <v-btn text color="primary" @click="resendCode()">
                          Resend code
                        </v-btn>
                      </v-col>
                    </div>

                    <div class="w-full d-flex login-button-secondary mt-6">
                      <div>
                        <v-btn text color="secondary" @click="back()"> Back </v-btn>
                      </div>
                      <div>
                        <v-btn class="w-25" block color="primary" type="submit"> Login </v-btn>
                      </div>
                    </div>
                  </v-form>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
import { mdiEyeOutline, mdiEyeOffOutline } from '@mdi/js';
import { ref, watch } from '@vue/composition-api';
import { useRouter } from '@core/utils';
import { required, emailValidator } from '@core/utils/validation';
import themeConfig from '@themeConfig';
import handleError from '@/plugins/handle-error';
import store from '@/store';
import { TwoFaTypeEnum } from '@/enums/two-fa-type.enum';
import ReCaptcha from '@/views/shared/recaptcha/ReCaptcha.vue';
import Vue from 'vue';

export default {
  components: { ReCaptcha },
  setup() {
    const captchaVerified = ref(false);
    const renderCaptcha = ref(false);
    const recaptchaRef = ref();
    const captchaVerificationPassed = () => captchaVerified.value = true;
    const captchaVerificationError = () => captchaVerified.value = false;
    const captchaVerificationExpired = () => captchaVerified.value = false;

    const loginForm = ref(null);
    const codeForm = ref(null);
    const { router } = useRouter();

    const showCodeForm = ref(false);
    const twoFaType = ref(null);
    const twoFaTypeEnum = TwoFaTypeEnum;
    const phone = ref(null);
    const isPasswordVisible = ref(false);

    const email = ref('');
    const code = ref('');
    const password = ref('');
    const errorMessages = ref({ email: null, password: null });

    const unsuccessfulLoginAttempts = ref(0);

    watch(unsuccessfulLoginAttempts, value => {
      if (renderCaptcha.value) {
        recaptchaRef.value.resetCaptcha();
      }

      if (value >= 3) {
        renderCaptcha.value = true;
      }
    });

    const handleFormSubmit = () => {
      const isFormValid = loginForm.value.validate();

      if (!isFormValid) return;

      if (renderCaptcha.value && !captchaVerified.value) {
        return Vue.notify({
          type: 'error',
          title: 'Captcha check required',
          text: 'Please proceed with captcha to continue',
        });
      }

      store
        .dispatch('auth/login', { email: email.value, password: password.value })
        .then((response) => {
          if (response.showCodeForm) {
            twoFaType.value = response.twoFaType;
            countDown.value = 60;
            phone.value = response.phone;
            countDownTimer();
            showCodeForm.value = true;
          } else {
            store.dispatch('auth/profile').then(() => {
              router.push('/');
            });
          }
        })
        .catch(error => {
          errorMessages.value = { email: handleError(error) };
          unsuccessfulLoginAttempts.value = unsuccessfulLoginAttempts.value + 1;
        });
    };

    const back = () => {
      showCodeForm.value = false;
    };

    const submitCode = () => {
      const isFormValid = codeForm.value.validate();

      if (!isFormValid) return;

      store
        .dispatch('auth/code', { email: email.value, code: code.value })
        .then(() => {
          store.dispatch('auth/profile').then(() => {
            router.push('/');
          });
        })
        .catch(error => (errorMessages.value = { code: handleError(error) }));
    };

    const countDown = ref(60);

    const resendCode = () => {
      store
        .dispatch('auth/login', { email: email.value, password: password.value })
        .then(() => {
            countDown.value = 60;
            countDownTimer();
        })
        .catch(error => (errorMessages.value = { code: handleError(error) }));
    };

    const countDownTimer = () => {
      if (countDown.value < 0) {
        countDown.value = 60;
      }
      if (countDown.value > 0) {
        setTimeout(() => {
          countDown.value -= 1;
          countDownTimer();
        }, 1000);
      }
    };

    return {
      handleFormSubmit,
      isPasswordVisible,
      email,
      password,
      errorMessages,
      showCodeForm,
      countDown,
      resendCode,
      codeForm,
      twoFaType,
      twoFaTypeEnum,
      phone,
      code,
      back,
      submitCode,
      icons: {
        mdiEyeOutline,
        mdiEyeOffOutline,
      },
      validators: {
        required,
        emailValidator,
      },
      appLogo: themeConfig.app.logo,
      loginForm,
      captchaVerified,
      renderCaptcha,
      recaptchaRef,
      captcha: {
        passed: captchaVerificationPassed,
        expired: captchaVerificationExpired,
        error: captchaVerificationError,
      }
    };
  },
};
</script>

<style lang="scss" scoped>
@import '@core/preset/preset/pages/auth.scss';

.login-button-secondary {
  justify-content: space-between;
}
</style>
